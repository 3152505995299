import React from 'react';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet';
import Header from './header';
import useSiteMetadata from '../../hooks/use-sitemetadata';
import Footer from './footer';

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          body {
            position: relative;
            min-height: 100vh;
            padding-bottom: 215px;
          }

          html,
          body {
            margin: 0;
            color: #555;
            font-family: 'Poppins', sans-serif, -apple-system,
              BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
              sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
              'Segoe UI Symbol';
            font-size: 18px;
            line-height: 1.4;

            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #222;
            line-height: 1.1;

            + * {
              margin-top: 0.5rem;
            }
          }

          li {
            margin-top: 0.25rem;
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />
      <main
        css={css`
          * {
            margin: 0 auto;
          }
          .content {
            margin: 2rem auto;
            max-width: 1140px;
            @media only screen and (max-width: 1140px) {
              padding: 0 1rem;
            }
            @media only screen and (max-width: 768px) {
              max-width: 540px;
            }
          }
        `}
      >
        {children}
      </main>
      <Footer />
      {/*<Header/>*/}
    </>
  );
};

export default Layout;
