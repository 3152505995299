import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const Wrapper = styled.div`
  background-color: #242424;
  padding: 0.5rem calc((100vw - 1140px - 0.5rem) / 2) 1rem;
  //position: absolute;
  //bottom: 0;
  ////left: 0;
  //width: 100%;

  @media only screen and (max-width: 1140px) {
    padding: 1rem;
  }
`;

const StyledLogo = styled(Image)`
  max-width: 245px;
  @media only screen and (max-width: 1140px) {
    max-width: 33%;
  }
  height: auto;
`;

const TextWrapper = styled.div`
  color: #ffffff;
  font-size: 0.9em;
`;

const Footer = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "footer-ghcp-logo.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <footer
      style={{
        backgroundColor: '#eee',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
      }}
    >
      <Wrapper>
        <StyledLogo fluid={image.sharp.fluid} />
        <TextWrapper>
          <p>Estr. dos Pinheiros, nº 90, R/c Esq.</p>
          <p>2415 - 566 Marrazes</p>
        </TextWrapper>
      </Wrapper>
    </footer>
  );
};

export default Footer;
