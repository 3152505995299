import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Image from 'gatsby-image';

import Hamburguer from '../../../assets/hamburguer.svg';

const NavLink = styled(Link)`
  border-radius: 4px;
  color: #222;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.6rem;
  text-decoration: none;
  :hover {
    background-color: #0088cc;
    color: #ffffff;
  }

  &.current-page {
    border-radius: 4px;
    background-color: #0088cc;
    color: #ffffff;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: 768px) {
    border-radius: 0;
    background-color: #0088cc;
    color: #ffffff;
    min-width: 250px;
  }
`;

const VerticalWrapper = styled.div`
  display: block;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  padding: 0.5rem calc((100vw - 1140px - 0.5rem) / 2);
  @media only screen and (max-width: 1140px) {
    padding: 0 1rem;
  }
`;

const MobileNavWrapper = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const DesktopNavWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    // minWidth: '250px',
    padding: '0!important',
    '&.current-page': {
      backgroundColor: '#0088cc',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#ffffff',
      },
    },
  },
}))(MenuItem);

const StyledLogo = styled(Image)`
  max-width: 150px;
`;

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "header-ghcp-logo.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <header
      css={css`
        background: #eee;
        border-bottom: 1px solid #ddd;
      `}
    >
      <Wrapper>
        <VerticalWrapper>
          <Link to="/" fontWeight="bold">
            <StyledLogo fluid={image.sharp.fluid} />
          </Link>
        </VerticalWrapper>
        <nav
          css={css`
            margin: auto 0;
          `}
        >
          <MobileNavWrapper>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Hamburguer
                style={{
                  border: '1px solid #0088CC',
                  color: '#0088CC',
                  padding: '2px',
                  maxWidth: '30px',
                }}
              />
            </Button>
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleClose}>
                <NavLink to="/">Home</NavLink>
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose}>
                <NavLink to="/about">Sobre nós</NavLink>
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose}>
                <NavLink to="/contact">Contactos</NavLink>
              </StyledMenuItem>
            </StyledMenu>
          </MobileNavWrapper>
          <DesktopNavWrapper>
            <NavLink to="/" activeClassName="current-page">
              Home
            </NavLink>
            <NavLink to="/about/" activeClassName="current-page">
              Sobre Nós
            </NavLink>
            <NavLink to="/contact/" activeClassName="current-page">
              Contactos
            </NavLink>
          </DesktopNavWrapper>
        </nav>
      </Wrapper>
    </header>
  );
};

export default Header;
